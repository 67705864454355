import axios from "axios";

axios.defaults.headers["Content-Type"] =
  "application/x-www-form-urlencoded; charset=UTF-8";

const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000,
});
// 添加请求拦截器
service.interceptors.request.use(
  (config) => {
    config.headers["token"] = localStorage.getItem("token");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    if (response.data.code == -1) {
      window.location.href = `/login`;
    }
    // 对响应数据做点什么
    return response;
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);
//get请求
const $get = (url, param) => {
  return new Promise((resolve) => {
    service({
      method: "get",
      url,
      params: param,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err, "异常");
      });
  });
};
//post请求
const $post = (url, param) => {
  return new Promise((resolve) => {
    service({
      method: "post",
      url,
      data: param,
    })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log(err, "异常");
      });
  });
};

export { service, $get, $post };
